<template>
<h1 class="text-center">CSSRulePlugin</h1>
</template>

<script>
export default {
  name: "CSSRulePlugin"
}
</script>

<style scoped>

</style>